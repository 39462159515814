<template>
  <div>
    <draggable
      v-if="searchResult[0] != ''"
      :list="searchResult"
      group="contacts"
      ghost-class=".ghost"
      class="py-3 transparent minHeight"
      @change="log($event)"
    >
      <v-card
        v-for="contact in searchResult"
        :key="contact.id"
        flat
        class="pa-0 mx-1 mb-2"
        @click="$refs.contactDisplayPopup.open(contact)"
      >
        <v-alert :color="getColor(contact.dispo)" class="pa-0 ma-0">
          <p class="text-caption my-0 mx-1 white--text font-weight-bold">
            {{
              typeof contact.dispo == "undefined" || contact.dispo == ""
                ? "NEW"
                : contact.dispo
            }}
          </p>
        </v-alert>
        <v-card-text class="px-2 py-3">
          <h3>{{ contact.name }}</h3>
          <h4 class="font-weight-regular">{{ contact.phone }}</h4>
        </v-card-text>
        <v-card-text
          class="px-2 py-0"
          v-if="contact.dealValue && contact.dealValue > 0"
        >
          <p class="mb-0">{{ currency }} {{ contact.dealValue }}</p>
        </v-card-text>
        <v-card-text class="px-2 py-0">
          <div v-if="contact.assignee" class="float-right mt-n8">
            <v-avatar color="lime darken-2" size="26" class="mr-1">
              <p class="my-0 white--text font-weight-medium">
                {{ getName(contact.assignee) }}
              </p>
            </v-avatar>
          </div>
        </v-card-text>
      </v-card>
    </draggable>

    <draggable
      v-else
      :list="contacts"
      group="contacts"
      ghost-class=".ghost"
      class="py-3 transparent minHeight"
      @change="log($event)"
    >
      <v-card
        v-for="contact in contacts"
        :key="contact.id"
        flat
        class="pa-0 mx-1 mb-2"
        @click="$refs.contactDisplayPopup.open(contact)"
      >
        <v-alert :color="getColor(contact.dispo)" class="pa-0 ma-0 rounded-b-0">
          <p class="text-caption my-0 mx-1 white--text font-weight-bold">
            {{
              typeof contact.dispo == "undefined" || contact.dispo == ""
                ? "NEW"
                : contact.dispo
            }}
          </p>
        </v-alert>
        <v-card-text class="px-2 pt-3 pb-1">
          <h3>{{ contact.name }}</h3>
          <h4 class="font-weight-regular">{{ contact.phone }}</h4>
        </v-card-text>
        <v-card-text
          class="px-2 py-0"
          v-if="contact.dealValue && contact.dealValue > 0"
        >
          <p class="mb-0">{{ currency }} {{ contact.dealValue }}</p>
        </v-card-text>
        <div
          v-if="contact.assignee && getName(contact.assignee) != ''"
          class="float-right mt-n8"
        >
          <v-avatar color="lime darken-2" size="26" class="mr-1">
            <p class="my-0 white--text font-weight-medium">
              {{ getName(contact.assignee) }}
            </p>
          </v-avatar>
        </div>
      </v-card>

      <!-- <v-card
        v-if="
          (contacts.length <= 0 || searchResult.length <= 0) && stageId == ''
        "
        class="add_button customBorderRadius"
        style="padding: 10px;cursor:pointer;min-height: 500px; display: flex;align-items: center;justify-content: center;"
        @click="addContact"
      >
        <p>
          <v-icon size="30" style="margin-right: 5px;color: #37cf37"
            >people</v-icon
          >
        </p>
        Add Contact
        <v-icon size="22" style="margin-right: 5px">add_circle</v-icon>
      </v-card> -->

      <v-card
        v-if="
          (contacts.length <= 0 || searchResult.length <= 0) && stageId == ''
        "
        class="add_button customBorderRadius"
        style="padding: 10px;cursor:pointer;min-height: 500px; display: flex; align-items: center; justify-content: center;"
        @click="addContact"
      >
        <div
          style="display: flex; flex-direction: column; align-items: center;"
        >
          <v-icon size="80" style="margin-right: 5px;color: #37cf37"
            >mdi-account</v-icon
          >
          <p style="display: flex; align-items: center;">
            Add Contact
            <v-icon size="30">add_circle</v-icon>
          </p>
        </div>
      </v-card>

      <v-card
        v-if="
          (contacts.length <= 0 || searchResult.length <= 0) && stageId != ''
        "
        class="add_button customBorderRadius"
        style="padding: 10px;min-height: 500px; display: flex; align-items: center; justify-content: center;"
      >
        <div
          style="display: flex; flex-direction: column; align-items: center;"
        >
          <v-icon size="80" style="margin-right: 5px;color: #37cf37"
            >mdi-account-switch</v-icon
          >
          <p
            style="display: flex; align-items: center;padding:10px;padding-left: 10px;"
            align="center"
          >
            You can drag a contact from New to Other stages or automate.
            <!-- <v-icon size="30">add_circle</v-icon> -->
          </p>
        </div>
      </v-card>
    </draggable>

    <v-btn
      v-if="count > contacts.length && searchResult[0] == ''"
      depressed
      block
      color="#ffffff55"
      @click="loadMore()"
    >
      LOAD MORE
    </v-btn>

    <!-- displayContact -->
    <contact-display-popup ref="contactDisplayPopup" @refresh="refresh" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import draggable from "vuedraggable";
import contactDisplayPopup from "../components/contactDisplayPopup.vue";
import fb from "../js/firebase.js";
export default {
  components: { draggable, contactDisplayPopup },
  props: {
    list: {
      type: String,
    },
    dispoNew: {
      type: Array,
    },
    stageId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      skip: 0,
      count: 0,
      contacts: [],
      minContact: 15,
      searchResult: [""],
      assignee: [],
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "dispoList"]),
    currency() {
      return this.$store.getters.companyDetails.currency;
    },
  },
  async created() {
    await this.getContact();
    // await this.getCount();
    await this.getAssigned();

    console.log("this.list", this.list);

    console.log("dispoNew", this.dispoNew);

    console.log("Stage ID", this.stageId);
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    addContact() {
      var self = this;
      this.$refs.contactDisplayPopup.open(false);
    },
    async getContact() {
      var predicates = [];

      if (this.stageId == "") {
        //NEW STAGE
        predicates = [
          {
            attribute: "stageId",
            type: "string",
            operator: "eq",
            value: "",
          },
          {
            attribute: "stageId",
            type: "string",
            operator: "exists",
            value: false,
          },
        ];
      } else {
        predicates = [
          {
            attribute: "stageId",
            type: "objectId",
            operator: "eq",
            value: this.stageId,
          },
        ];
      }

      await this.$http
        .post(`${this.$store.state.ENDPOINT}/contacts/byFilterV4`, {
          queryCase: "data",
          lists: [this.list],
          limit: this.minContact,
          skip: this.skip,
          matchType: "or",
          predicates: predicates,
          dispos: this.dispoNew,
        })
        .then(async (response) => {
          this.contacts = response.data;
          await this.getCount();
        });
    },
    getCount() {
      var predicates = [];

      if (this.stageId == "") {
        //NEW STAGE
        predicates = [
          {
            attribute: "stageId",
            type: "string",
            operator: "eq",
            value: "",
          },
          {
            attribute: "stageId",
            type: "string",
            operator: "exists",
            value: false,
          },
        ];
      } else {
        predicates = [
          {
            attribute: "stageId",
            type: "objectId",
            operator: "eq",
            value: this.stageId,
          },
        ];
      }

      console.log("this.stageId", this.stageId);

      this.$http
        .post(`${this.$store.state.ENDPOINT}/contacts/byFilterV4`, {
          queryCase: "count",
          lists: [this.list],
          limit: this.minContact,
          skip: this.skip,
          matchType: "or",
          predicates: predicates,
          dispos: this.dispoNew,
        })
        .then((response) => {
          console.log("response.data", response.data);

          this.count = response.data.count;
          console.log("count: ", this.count);

          this.$emit("updateCount", {
            stageId: this.stageId,
            count: this.count,
          });
        });
    },
    async getAssigned() {
      var filter = {
        where: {
          companyId: this.$store.getters.user.companyId,
          id: this.list,
        },
        include: {
          relation: "accounts",
          scope: {
            fields: ["name", "id"],
          },
        },
      };
      try {
        var response = await this.$http.get(
          `${this.$store.state.ENDPOINT}/lists?filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`
        );
        this.assignee = response.data[0].accounts;
        // console.log("response: ",response.data[0].accounts);
      } catch (e) {
        console.log("err: ", e);
      }
    },
    getName(id) {
      // console.log("id: ",id);
      // console.log("this.assignee: ",this.assignee);
      var assign = this.assignee.find((c) => c.id == id);
      // console.log("name: ",assign.name);
      var name = assign ? assign.name.substr(0, 2).toUpperCase() : "";
      return name;
    },
    loadMore() {
      this.skip = this.skip + this.minContact;
      var predicates = [];

      if (this.stageId == "") {
        //NEW STAGE
        predicates = [
          {
            attribute: "stageId",
            type: "string",
            operator: "eq",
            value: "",
          },
          {
            attribute: "stageId",
            type: "string",
            operator: "exists",
            value: false,
          },
        ];
      } else {
        predicates = [
          {
            attribute: "stageId",
            type: "objectId",
            operator: "eq",
            value: this.stageId,
          },
        ];
      }

      this.$http
        .post(`${this.$store.state.ENDPOINT}/contacts/byFilterV4`, {
          queryCase: "data",
          lists: [this.list],
          limit: this.minContact,
          skip: this.skip,
          matchType: "or",
          predicates: predicates,
          dispos: this.dispoNew,
        })
        .then((response) => {
          this.contacts.push(...response.data);
          // console.log("Loading more: ", this.contacts);
          return;
        });
    },
    getColor(dispo) {
      const self = this;
      try {
        if (typeof dispo == "undefined" || dispo == "NEW" || dispo == "")
          return "primary";
        var d = _.find(self.dispoList, (d) => {
          return d.title == dispo;
        });
        if (d == undefined) {
          return "#E5E5E5";
        }
        return d.hex;
      } catch (e) {
        console.log("error", e);
      }
    },
    async log(evt) {
      if (evt.added) {
        console.log("success");

        var item = evt.added.element;
        await this.$http.patch(`${this.ENDPOINT}/contacts/${item.id}`, {
          stageId: this.stageId,
        });
        this.count = this.count + 1;

        console.log("success");

        this.refresh();

        // this.getContact();
        // this.getAssigned();

        try {
          fb.log("contact_moved");
        } catch (e) {
          console.log("Error in FB log", e);
        }
      }
      if (evt.removed) {
        this.count = this.count - 1;
      }
    },
    async conSearch(search) {
      // console.log("search: ", search);
      if (search != "") {
        var byFilterObj = {
          queryCase: "data",
          matchType: "or",
          predicates: [
            {
              attribute: "phone",
              operator: "contains",
              type: "string",
              value: search,
            },
            {
              attribute: "name",
              operator: "contains",
              type: "string",
              value: search,
            },
          ],
          lists: [this.list],
          stageIds: this.stageId != "" ? [this.stageId] : [],
          dispo: this.dispoNew,
        };

        await this.$http
          .post(
            `${this.$store.state.ENDPOINT}/contacts/byFilterV4`,
            byFilterObj
          )
          .then((res) => {
            if (this.stageId == "")
              this.searchResult = res.body.filter((c) => c.stageId == "");
            else this.searchResult = res.body;
            // console.log("this.searchResult: ", this.searchResult);
          });
      }
    },
    removeSearch() {
      this.searchResult = [""];
      this.skip = 0;
      this.getContact();
      // this.getCount();
      this.getAssigned();
    },
    async filterByDispo(dispos) {
      console.log("dddddddd", dispos);
      this.dispoNew = dispos;
      this.getContact();
      // this.getCount();
      this.getAssigned();
    },
  },
};
</script>

<style>
.minHeight {
  min-height: 68vh;
}
</style>
