<template>
  <span v-if="totalDeal>0" class="ml-8 font-weight-medium">Total Deal : {{currency}} {{totalDeal}}</span>
</template>

<script>
export default {
props: {
    list: {
      type: String,
    },
    stageId: {
      type: String,
      default: "",
    },
  },
data() {
    return { contacts: [], totalDeal:''};
  },
  computed: {
    
    currency() {
      return this.$store.getters.companyDetails.currency;
    },
  },
created() {
    this.getContacts();
  },
methods:{
	getContacts()
	{
    // console.log("currency",this.$store.getters.companyDetails.currency);
		var predicates = [];

      if (this.stageId == "") {
        //NEW STAGE
        predicates = [
          {
            attribute: "stageId",
            type: "string",
            operator: "eq",
            value: "",
          },
          {
            attribute: "stageId",
            type: "string",
            operator: "exists",
            value: false,
          },
        ];
      } else {
        predicates = [
          {
            attribute: "stageId",
            type: "objectId",
            operator: "eq",
            value: this.stageId,
          },
        ];
      }

      this.$http
        .post(`${this.$store.state.ENDPOINT}/contacts/byFilterV4`, {
          queryCase: "data",
          lists: [this.list],
          matchType: "or",
          predicates: predicates,
        })
        .then((response) => {
          this.contacts.push(...response.data);
					var deal = this.contacts.map(con=>con.dealValue);
          deal = deal.filter(dV=>dV!=undefined)
					this.totalDeal =  deal.reduce((total,d)=>{return total+parseInt(d)},0);
          // console.log("deals: ", deal);
          // console.log("this.totalDeal: ", this.totalDeal);
          return;
        });
    
	}
}
}
</script>

<style>

</style>