<template>
  <v-card min-height="93vh" flat tile class="pa-0 mb-n15 primarygrad">
    <!-- Top Row -->
    <v-row class="pa-0 ma-0">
      <v-col cols="6" md="4">
        <v-autocomplete
          v-model="selectedList"
          :items="lists"
          item-text="name"
          item-value="id"
          label="Lists"
          prepend-inner-icon="view_list"
          autocomplete="false"
          hide-details
          dense
          filled
          solo-inverted
          flat
          class="pt-1"
          @change="refresh"
        />
      </v-col>
      <v-col cols="6" md="4">
        <DispoComponent @filterByDispo="dispoFilter" />
      </v-col>
      <v-col cols="10" md="4" class="d-flex">
        <Search @searchCon="searchInAllStages" @reset="resetAll" />
        <v-chip
          label
          dark
          class="pa-5 pl-4 ml-1 grey darken-3"
          @click="automationDialog = true"
        >
          <v-icon size="26">mdi-robot-excited-outline</v-icon>
        </v-chip>
        <!-- <v-text-field
            label="Search"
            v-model="allSearch"
            prepend-inner-icon="mdi-magnify"
            append-icon='mdi-send'
            @click:append="searchInAllStages"
            @click:clear="resetAll"
            clearable
            dense
            flat
            solo-inverted
            class="pt-1 mx-2"
          />
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-1"
                depressed
                dark
                v-bind="attrs"
                v-on="on">
                Filter
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title>Assigned to me</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Assigned to ..</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Due date</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        <!-- <listSearchFilter
        ></listSearchFilter> -->
      </v-col>
    </v-row>
    <!-- Stages -->
    <v-container v-if="stages.length > 0" fluid class="d-flex scroller">
      <v-card
        v-for="(item, index) in stages"
        :key="index"
        height="80vh"
        flat
        :width="item.pipelineview * 68"
        :min-width="item.pipelineview * 68"
        class="mx-1 fill-height glass"
      >
        <!-- minimised form -->
        <v-card
          v-if="item.pipelineview == 1"
          flat
          class="transparent fill-height"
        >
          <div class="d-flex justify-center">
            <v-hover v-slot="{ hover }">
              <v-btn
                :dark="hover"
                depressed
                :fab="hover"
                :icon="!hover"
                :x-small="hover"
                :small="!hover"
                class="mt-3"
                @click="changeWidth(item)"
              >
                <v-icon>mdi-arrow-expand</v-icon>
              </v-btn>
            </v-hover>
          </div>
          <v-card flat class="transparent fill-height pt-15">
            <h3 class="text-no-wrap rot-90">
              {{ item.name }}
              <TotalDealValue
                :list="selectedList"
                :stageId="item.id ? item.id : ''"
                v-if="item.id"
              />
            </h3>
          </v-card>
        </v-card>
        <!-- expanded form stage -->
        <v-card v-else flat class="transparent fill-height rel">
          <!-- top row -->
          <v-row class="pa-0 ma-0">
            <!-- Stage Name -->
            <v-col cols="6" class="pa-0 ma-0 cusfield">
              <v-text-field
                v-model="item.name"
                solo
                flat
                dense
                single-line
                hide-details
                class="pa-0 ma-0 fs font-weight-bold dotted-gradient"
                background-color="transparent"
                @blur="newStageName(item)"
              />
            </v-col>

            <v-col cols="3" class="pt-2 ma-0 cusfield">
              <span class="stage-count"
                ><b>{{ item.count || 0 }}</b></span
              >
            </v-col>
            <!-- Action buttons -->
            <v-col cols="3" class="pa-0 pr-2 ma-0 d-flex justify-end">
              <v-hover v-slot="{ hover }">
                <v-btn
                  :dark="hover"
                  depressed
                  :x-small="hover"
                  :small="!hover"
                  :fab="hover"
                  :icon="!hover"
                  class="mt-1"
                  @click="beforesearch(index)"
                >
                  <v-icon regular>mdi-magnify</v-icon>
                </v-btn>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-btn
                  :dark="hover"
                  depressed
                  :x-small="hover"
                  :small="!hover"
                  :fab="hover"
                  :icon="!hover"
                  class="mt-1"
                  @click="changeWidth(item)"
                >
                  <v-icon regular>mdi-arrow-collapse</v-icon>
                </v-btn>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-btn
                  v-if="item.id"
                  :color="hover ? 'red' : null"
                  :dark="hover"
                  depressed
                  :x-small="hover"
                  :small="!hover"
                  :fab="hover"
                  :icon="!hover"
                  class="mt-1"
                  @click="removeStage(item)"
                >
                  <v-icon regular>mdi-close</v-icon>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
          <!-- search row -->
          <v-row v-if="searchSelected == index" class="pl-3 pt-0 pb-2 ma-0">
            <v-col cols="9" class="pa-0 ma-0">
              <v-text-field
                v-model="search"
                solo
                flat
                dark
                dense
                single-line
                hide-details
                class="pa-0 ma-0 fs"
                background-color="#00000055"
              />
            </v-col>
            <v-col cols="3" class="pa-0 ma-0 d-flex">
              <v-icon @click="searchCon(item)">mdi-arrow-right-bold</v-icon>
              <v-icon @click="resetSearchCon(item)">mdi-close-circle</v-icon>
            </v-col>
          </v-row>
          <!-- draggable -->
          <v-card
            flat
            :height="index == searchSelected ? '68vh' : '74vh'"
            class="transparent mt-0 pt-0 mx-2 scroller"
          >
            <Stage
              :list="selectedList"
              :stageId="item.id ? item.id : ''"
              :dispoNew="disposSelected"
              :ref="'stage' + item.id"
              @refresh="refresh()"
              @updateCount="handleStageCount"
            />
          </v-card>
        </v-card>
      </v-card>

      <!-- Add Stage -->
      <v-card
        height="100px"
        flat
        :width="4 * 68"
        :min-width="4 * 68"
        class="mx-1 glass1"
      >
        <!-- expanded form stage -->
        <v-card flat align="center" justify="center" class="transparent rel">
          <!-- top row -->
          <v-row class="pa-0 ma-0">
            <v-col>
              <v-btn
                class="glass transparent"
                depressed
                style="top:50%"
                @click="addStage()"
              >
                <v-icon>add</v-icon>
                Add Stage
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-container>
    <v-container v-else>
      <div class="text-center">
        <v-img
          class="mx-auto my-5"
          width="250"
          src="../assets/assignMembers.png"
        />
        <h2 class="font-weight-medium">
          No Stages if <b>All lists</b> is selected. Please select a single List
        </h2>
      </div>
    </v-container>

    <v-dialog v-model="automationDialog" width="850">
      <v-card class="secondarygrad">
        <v-card-title class="primarygrad">
          Automation
          <v-btn
            class="ma-md-2"
            small
            icon
            fab
            href="https://youtu.be/mJY9WJcSE9I"
            target="_blank"
          >
            <v-icon color="secondary">play_circle_filled</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="automationDialog = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <automation-rules
            :list="
              lists.find((r) => {
                return r.id == selectedList;
              })
            "
            :allLists="lists"
            @refresh="refresh()"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed dark @click="automationDialog = false">
            CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

import fb from "../js/firebase.js";
import Stage from "../components/stage.vue";
import TotalDealValue from "../components/totalDealValue.vue";
import DispoComponent from "../components/dispoComponent.vue";
import automationRules from "../components/automationRules.vue";
import Search from "../components/search.vue";
export default {
  components: {
    Stage,
    TotalDealValue,
    DispoComponent,
    Search,
    automationRules,
  },
  data() {
    return {
      lists: [],
      stages: [],
      disposSelected: [],
      search: "",
      searchSelected: -1,
      dueDateDialog: false,
      dueDate: "",
      selectedContact: "",
      loader: false,
      tempStage: {},
      filterActive: false,
      automationDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "user",
      "dispoList",
      "fieldList",
      "teams",
      "selectedTeam",
    ]),
    nostages() {
      return require("../assets/noListContact.png");
    },
    selectedList: {
      get: function() {
        return this.$store.getters.selectedList;
      },
      set: function(newValue) {
        this.$store.commit("setSelectedList", newValue);
      },
    },
  },
  async created() {
    await this.fetchLists();
    await this.getStages();
  },
  methods: {
    handleStageCount(data) {
      console.log("data", data);

      // If stageId is an empty string, update the first stage's count
      if (data.stageId === "") {
        if (this.stages.length > 0) {
          this.$set(this.stages[0], "count", data.count);
        }
      } else {
        // Otherwise, find the stage by ID and update its count
        let stage = this.stages.find((stage) => stage.id === data.stageId);
        if (stage) {
          this.$set(stage, "count", data.count);
        }
      }
    },
    async changeWidth(item) {
      var payload = {};
      if (item.pipelineview == 1) {
        payload.pipelineview = 4;
        item.pipelineview = 4;
      } else {
        payload.pipelineview = 1;
        item.pipelineview = 1;
      }
      if (item.id) {
        await this.$http.patch(`${this.ENDPOINT}/stages/${item.id}`, payload);
      }
      console.log("Changed width");
      console.log("this.search", this.search);
      if (item.pipelineview == 4 && this.search != "") {
        this.$refs[`stage${item.id}`][0].conSearch(this.search);
      }

      this.refresh();
    },
    async newStageName(item) {
      if (item.id) {
        var payload = {};
        payload.name = item.name;
        await this.$http.patch(`${this.ENDPOINT}/stages/${item.id}`, payload);
      }

      this.refresh();
    },
    async addStage() {
      var stage = {};
      stage.name = "New Stage";
      stage.desc = "Add stage discription";
      stage.pipelineview = 4;
      stage.listId = this.selectedList;
      var res = await this.$http
        .post(`${this.ENDPOINT}/stages/`, stage)
        .then((res) => {
          console.log("res.body: ", res.body);
          this.stages.push(res.body);
          console.log("stages: ", this.stages);

          this.$swal({ type: "success", text: "New Stage created" });
        })
        .catch((e) => {
          console.log("err", e);
        });
      try {
        fb.log("new_stage");
      } catch (e) {
        console.log("Error in FB log", e);
      }
    },
    async removeStage(stage) {
      // var index = this.stages.indexOf(stage);
      this.$swal({
        title: "Are you sure?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#31a848",
        cancelButtonColor: "#f44336",
        confirmButtonText: "Yes, Remove it!",
      }).then((willDelete) => {
        if (willDelete.value) {
          this.$http
            .delete(`${this.ENDPOINT}/stages/${stage.id}`)
            .then((response) => {
              this.$swal({ type: "success", text: "Successful" });
              // this.stages.splice(index, 1);
              this.refresh();
              try {
                fb.log("remove_stage");
              } catch (e) {
                console.log("Error in log", e);
              }
            })
            .catch((err) => {
              console.log("err", err);
              this.$swal({ type: "error", text: err.body.error.message });
            });
        }
      });
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];
        var teamIds = this.getTeamIds();
        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;
        if (!this.selectedList) {
          this.selectedList = this.lists[0].id;
        }
        // console.log("List: ",this.lists)
      } catch (e) {
        console.log("error in list fetch", e);
      }
    },
    async getStages() {
      this.stages = [];
      var newStage = {
        name: "New",
        desc: "Contacts without Stage Id",
        pipelineview: 4,
        listId: this.selectedList,
      };
      var listId = this.selectedList;
      var response = await this.$http.get(
        `${this.ENDPOINT}/lists/${listId}/stages`
      );
      this.stages = response.body;
      this.stages.splice(0, 0, newStage);
      this.stages.forEach((s) => {
        s["edit"] = false;
      });
      // console.log("stages: ",this.stages);
    },
    beforesearch(index) {
      this.searchSelected = index;
      this.search = "";
    },
    async searchCon(stage) {
      this.$refs[`stage${stage.id}`][0].conSearch(this.search);
    },
    resetSearchCon(stage) {
      this.searchSelected = -1;
      this.search = "";
      this.$refs[`stage${stage.id}`][0].removeSearch();
    },
    async refresh() {
      console.log("refreshed: ");
      await this.getStages();
      this.search = "";
      this.searchSelected = -1;
    },
    searchInAllStages(search) {
      if (search != "") {
        if (this.searchSelected > -1) {
          this.searchSelected = -1;
        }
        this.search = search;

        console.log("search is", this.search);
        for (var i in this.stages) {
          var stage = this.stages[i];
          console.log("stage is created", typeof stage.id);
          // if (typeof stage.id == "string") {
          //   console.log(
          //     "this.$refs",

          //   );

          console.log("this.$refs", this.$refs);

          this.$refs[`stage${stage.id}`][0].conSearch(search);
          // }
        }
      } else {
        this.resetAll();
      }
    },
    resetAll() {
      console.log("this.stages", this.stages);

      for (var i in this.stages) {
        var stage = this.stages[i];
        this.$refs[`stage${stage.id}`][0].removeSearch();
      }
    },
    dispoFilter(selectedDispo) {
      console.log("selectedDispo", selectedDispo);

      // this.resetAll();

      this.searchSelected = -1;
      this.search = "";

      this.disposSelected = selectedDispo;
      console.log("dispo in Parent: ", this.disposSelected);

      for (var i in this.stages) {
        var stage = this.stages[i];
        this.$refs[`stage${stage.id}`][0].filterByDispo(this.disposSelected);
      }
    },
  },
};
</script>

<style scoped>
.scroll {
  overflow-x: scroll;
}
.fs {
  font-size: 14px;
}
.rot-90 {
  transform: rotate(90deg);
}
.rel {
  position: relative;
  padding-bottom: 2.5rem;
}
.abs {
  position: absolute;
  bottom: 0px;
}
.glass {
  background-color: rgba(202, 238, 178, 0.363);
}
.glass1 {
  background-color: rgba(202, 238, 178, 0.884);
}
</style>
<style>
.cusfield .v-input input:focus {
  border: 3px solid #00cc6d !important;
  border-radius: 3px;
}
.scroller {
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(24, 78, 7, 0.219);
  border-radius: 5px;
}

.dotted-gradient {
  background-image: linear-gradient(
    to right,
    #313131 40%,
    rgba(255, 255, 255, 0) 40%
  );
  background-position: bottom;
  background-size: 6px 2px;
  background-repeat: repeat-x;
  text-transform: inherit;
  padding-bottom: 2px;
}
</style>
